
import ArticleItemNew from "@/components/ArticleItemNew";
import SnapTray from "@/components/SnapTray";
import CategoriesTray from "@/components/CategoriesTray";

import SHeader from "@/components/SHeader";
import SFooter from "@/components/SFooter";
// import { FETCH_MORE_ARTICLES_BY_IDS } from "@/store/actionTypes.js";
import ArticleService from "@/services/articlesService.js";

export default {
  head() {
    return {
      meta: [{ hid: "robots", name: "robots", content: "index" }],
    };
  },
  name: "home",

  components: {
    // SnapItem,
    // ArticleItem,
    ArticleItemNew,
    sheader: SHeader,
    sfooter: SFooter,
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        console.log("inside watch", bottom);
        this.getArticles();
      }
    },
    // locationInfo(newValue, oldValue) {
    //   this.getArticlesByLocation(newValue);
    // }
    $route(to, from) {
      console.log("Inside route watch", to, from);
      this.handleLocationParams();
    },
  },
  created() {
    // this.handleLocationParams();
    console.log("oh here it is");
    console.log(
      "query parametes for the home page loading is ",
      this.$route.query
    );
    if (
      this.$route.query.line3 ||
      this.$route.query.city ||
      this.$route.query.state ||
      this.$route.query.country
    ) {
      this.locationList.push(this.$route.query);
      this.getArticlesByLocation(this.$route.query);
    } else {
      this.locationList = [];
      var self = this;
      this.$store.dispatch("location/FETCH_CURRENT_LOCATION").then(
        (response) => {
          this.getArticlesByLocation(
            this.$route.query.category
              ? {
                  ...this.$store.state.location,
                  category: this.$route.query.category,
                }
              : this.$store.state.location
          );
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again"
          );
        }
      );
    }
  },
  destroyed() {
    if (process.client) {
      this.locationList = [];
      document.removeEventListener("scroll", this.handleScroll);
    }
  },
  beforeMount() {
    console.log("mounting home ....");
    // this.$store.state.home.articles = [];
    console.log(this.$store);
    this.$store.commit("home/CLEAR_ARTICLES");
    this.$store.commit("home/CLEAR_SNAPS");
  },
  computed: {
    articles() {
      return this.$store.state.home.articles;
    },

    isLoggedIn: function () {
      return this.$auth.loggedIn;
      // if (this.$auth.user.userAccessToken) {
      //   return true;
      // } else {
      //   return false;
      // }
    },

    snaps() {
      return this.$store.state.home.snaps;
    },

    locationInfo() {
      // this.getArticlesByLocation(this.$store.state.location);
      return this.$store.state.location;
    },
    uniqueIdComputed() {
      return this.uniqueId;
    },
    categoryQuery() {
      return this.$route.query.category;
    },
  },
  props: {
    location: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userInfo: { city: "Fetching Location ...", state: "", country: "" },
      userMessage: "Loading...",
      bottom: false,
      allArticles: [],
      currentSplitIndex: 0,
      currentSnapSplitIndex: 0,
      articleIds: [],
      snapIds: [],
      numberOfArticles: 5,
      articlesPresent: true,
      snapsPresent: true,
      uniqueId: 0,
      snap: null,
      categories: [
        ["Highlights", "star"],
        ["Politics", "ballot"],
        ["Sports", "directions_bike"],
        ["Entertainment", "movie"],
        ["Events", "event"],
        ["Classifieds", "ads_click"],
        ["Health", "health_and_safety"],
        ["Technology", "memory"],
        ["Local Issues", "fmd_bad"],
      ],
      bottomThreshold: 0.8, // When page is scrolled down 80% then we get next set of articles
      locationList: [],
      // endOfArticles: false
    };
  },
  methods: {
    changeUniqueId() {
      this.uniqueId = Math.random();
    },
    bottomVisible() {
      if (process.client) {
        const scrollY = window.scrollY;
        const visible = document.documentElement.clientHeight;
        const pageHeight = document.documentElement.scrollHeight;
        const bottomOfPage =
          visible + scrollY >= this.bottomThreshold * pageHeight;
        return bottomOfPage || pageHeight < visible;
      } else {
        return false;
      }
    },
    handleLocationParams() {
      this.$store.commit("home/CLEAR_ARTICLES");
      this.$store.commit("home/CLEAR_SNAPS");
      this.locationList = [];
      if (process.client) {
        document.addEventListener("scroll", this.handleScroll);
      }

      this.userMessage = "Loading...";
      if (
        this.$route.query.line3 ||
        this.$route.query.city ||
        this.$route.query.state ||
        this.$route.query.country
      ) {
        this.getArticlesByLocation(this.$route.query);
      } else if (this.$store.state.location.country) {
        this.changeUniqueId();
        this.getArticlesByLocation(this.$store.state.location);
      } else {
        this.changeUniqueId();
        this.$store.dispatch("location/FETCH_CURRENT_LOCATION").then(
          (response) => {
            console.log("fetched current location..");
            console.log(response);
            this.getArticlesByLocation(
              this.$route.query.category
                ? {
                    ...this.$store.state.location,
                    category: this.$route.query.category,
                  }
                : this.$store.state.location
            );
          },
          (error) => {
            console.error(
              "Got nothing from server. Prompt user to check internet connection and try again"
            );
          }
        );
      }
    },

    handleScroll() {
      this.bottom = this.bottomVisible();
    },
    getArticles() {
      console.log(
        "Inside getArticles with currentSplitIndex ",
        this.currentSplitIndex
      );
      if (
        this.articleIds.length <
        this.currentSplitIndex * this.numberOfArticles
      ) {
        this.userMessage = "";
        console.log("end of articles reached");
        return;
      }

      let endIndex;
      if (
        this.articleIds.length >
        (this.currentSplitIndex + 1) * this.numberOfArticles
      ) {
        endIndex = (this.currentSplitIndex + 1) * this.numberOfArticles;
      } else {
        endIndex = this.articleIds.length;
        // this.userMessage = "That is all the articles posted in your location.";
        this.userMessage = "";
        // this.endOfArticles = true;
      }
      console.log("endIndex is ", endIndex);
      if (this.currentSplitIndex > 0) {
        if (endIndex < this.articleIds.length - 1) {
          this.userMessage = "Fetching More ...";
        } else {
          this.userMessage = "";
        }
      }

      var currentSplitArticleIds = this.articleIds.slice(
        this.currentSplitIndex * this.numberOfArticles,
        endIndex
      );
      if (this.currentSplitIndex === 0) {
        this.$store.commit("home/CLEAR_ARTICLES"); //TODO: utilize methods from mutation to do this.
      }

      this.$store.dispatch(
        "home/FETCH_MORE_ARTICLES_BY_IDS_SEQUENTIAL",
        currentSplitArticleIds
      );

      console.log(this.bottomVisible(), this.currentSplitIndex);
      this.currentSplitIndex += 1;
    },
    getSnaps() {
      console.log(
        "Inside getSnaps with currentSplitIndex ",
        this.currentSnapSplitIndex
      );
      if (
        this.snapIds.length <
        this.currentSnapSplitIndex * this.numberOfSnaps
      ) {
        this.userMessage = "";
        console.log("end of snap reached");
        return;
      }

      let endIndex;
      if (
        this.snapIds.length >
        (this.currentSnapSplitIndex + 1) * this.numberOfSnaps
      ) {
        endIndex = (this.currentSnapSplitIndex + 1) * this.numberOfSnaps;
      } else {
        endIndex = this.snapIds.length;
        // this.userMessage = "That is all the articles posted in your location.";
        this.userMessage = "";
        // this.endOfArticles = true;
      }
      console.log("endIndex is ", endIndex);
      if (this.currentSnapSplitIndex > 0) {
        if (endIndex < this.snapIds.length - 1) {
          this.userMessage = "Fetching More ...";
        } else {
          this.userMessage = "";
        }
      }

      var currentSplitSnapIds = this.snapIds.slice(
        this.currentSnapSplitIndex * this.numberOfSnaps,
        endIndex
      );
      if (this.currentSnapSplitIndex === 0) {
        this.$store.commit("home/CLEAR_SNAPS"); //TODO: utilize methods from mutation to do this.
      }

      this.$store.dispatch(
        "home/FETCH_MORE_SNAPS_BY_IDS_SEQUENTIAL",
        currentSplitSnapIds
      );

      console.log(this.bottomVisible(), this.currentSnapSplitIndex);
      this.currentSnapSplitIndex += 1;
    },
    getSnapsByLocation(userInfo) {
      this.$store.commit("home/CLEAR_SNAPS");
      var newAddressQuery = {
        line3: userInfo.line3,
        city: userInfo.city,
        state: userInfo.state ? userInfo.state : userInfo.region,
        country: userInfo.country,
        only_ids: true,
        is_snaps: true,
      };
      ArticleService.getArticleIdsByLoc(newAddressQuery)
        .then(({ data }) => {
          if (data && data.results.length > 0) {
            this.snapIds = data.results;
            this.snapsPresent = true;
            this.getSnaps();
          } else {
            this.snapsPresent = false;
            // self.userMessage = "Loading...";
            // ArticleService.getAllSnapIds()
            //   .then(({ data }) => {
            //     if (data && data.results.length > 0) {
            //       this.snapIds = data.results;
            //       this.getSnaps();
            //     } else {
            //       // self.userMessage = "Couldn't load any articles.";
            //     }
            //   })
            //   .catch(error => {});
          }
        })
        .catch((error) => {
          console.log("Error getting articleIds by location.");
          self.userMessage = "Something went wrong, please refresh the page.";
          console.log(error);
          throw new Error(error);
        });
    },
    getArticlesByLocation(userInfo) {
      this.getSnapsByLocation(userInfo);
      console.log("Inside getArticlesByLocation in Home.vue");
      this.$store.commit("home/CLEAR_ARTICLES");
      if (process.client) {
        document.addEventListener("scroll", this.handleScroll);
      }

      // console.log(payload.city);
      // console.log(payload.typeValue);
      this.currentSplitIndex = 0;
      // if (this.userInfo.city !== undefined) {
      this.userMessage = "Loading...";
      console.log(userInfo);
      var newAddressQuery = {
        line3: userInfo.line3,
        city: userInfo.city,
        state: userInfo.state ? userInfo.state : userInfo.region,
        country: userInfo.country,
        category: userInfo.category
          ? userInfo.category
          : this.$route.query.category,
        only_ids: true,
      };
      var self = this;
      console.log("before query");
      console.log(newAddressQuery);
      ArticleService.getArticleIdsByLoc(newAddressQuery)
        .then(({ data }) => {
          console.log("getting article ids.");
          console.log(data);
          if (data && data.results.length > 0) {
            this.articleIds = data.results;
            self.articlesPresent = true;
            this.getArticles();
          } else {
            self.articlesPresent = false;
            // self.userMessage = "Loading...";
            ArticleService.getAllArticleIds()
              .then(({ data }) => {
                if (data && data.results.length > 0) {
                  this.articleIds = data.results;
                  this.getArticles();
                } else {
                  self.userMessage = "Couldn't load any articles.";
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {
          console.log("Error getting articleIds by location.");
          self.userMessage = "Something went wrong, please refresh the page.";
          console.log(error);
          throw new Error(error);
        });
    },
    onShareNews() {
      this.$router.push({
        name: "Login",
        params: {
          successMessage: "To start writing local news, join Street.",
        },
      });
    },
  },
};

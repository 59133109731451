
export default {
  name: "CategoriesTray",
  props: {
    categories: {
      type: Array,
      default: [],
    },
    currentCategory: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    changeToHttps: function (imageUrl) {
      let newUrl = imageUrl.replace("http:", "https:");
      return newUrl;
    },
    onCategoryClick: function (category) {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, category: category[0].toLowerCase() },
      });
    },
    isActive: function (category) {
      console.log("isactive called." + this.currentCategory);
      if (this.currentCategory && category) {
        console.log(
          "is active called " + this.currentCategory + "  " + category
        );
        return category.toLowerCase() == this.currentCategory.toLowerCase();
      }
      return false;
    },
  },
};

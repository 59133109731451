
const uuidv4 = require("uuid/v4");
import ArticlesService from "@/services/articlesService.js";
import months from "@/util/date.js";
export default {
  name: "SnapView",
  props: ["snap", "close_snap"],
  data: () => ({}),
  created: function () {},
  computed: {
    initials: function () {
      let initials = "";
      if (this.snap.author.firstname) {
        initials = initials + this.snap.author.firstname[0];
      }
      if (this.snap.author.lastname) {
        initials = initials + this.snap.author.lastname[0];
      }
      return initials;
    },
  },
  methods: {
    getTime: function (timestamp) {
      const date = new Date(timestamp);
      const month = months[date.getMonth()];
      const year = date.getFullYear();
      const day = date.getDate();
      const articlePublishDate = month + " " + day + " " + year;
      return articlePublishDate;
    },
    onClosePreview() {
      this.close_snap();
    },
    changeToHttps: function (imageUrl) {
      let newUrl = imageUrl.replace("http:", "https:");
      return newUrl;
    },
    // getTime: function(timestamp) {
    //   const date = new Date(timestamp);
    //   const month = months[date.getMonth()];
    //   const year = date.getFullYear();
    //   const day = date.getDate();
    //   const articlePublishDate = month + " " + day + " " + year;
    //   return articlePublishDate;
    // }
  },
};


import Stime from "@/util/stime.js";
export default {
  name: "article-item-new",
  props: {
    article: {
      required: true,
    },
  },
  beforeMount() {
    console.log("inside article item new", this.article);
  },
  computed: {
    initials: function () {
      let initials = "";
      if (this.article.author.firstname) {
        initials = initials + this.article.author.firstname[0];
      }
      if (this.article.author.lastname) {
        initials = initials + this.article.author.lastname[0];
      }
      return initials;
    },
    articleLink: function () {
      return {
        path: `/articles/${this.article.slug}`,
        params: {
          slug: this.article.slug,
          article: this.article,
        },
      };
    },
    articleImageUrl: function () {
      if (this.article && this.article.image) {
        return this.changeToHttps(this.article.image); // + "=w320"
      }
    },
    ffdiff: function () {
      let factLen = 0;
      let fictionLen = 0;
      for (let index = 0; index < this.article.ffs.length; index++) {
        if (this.article.ffs[index].type == "FACT") {
          ++factLen;
        } else if (this.article.ffs[index].type == "FICTION") {
          ++fictionLen;
        }
      }
      return factLen - fictionLen;
    },
    factText: function () {
      let factLen = 0;
      for (let index = 0; index < this.article.ffs.length; index++) {
        if (this.article.ffs[index].type == "FACT") {
          ++factLen;
        }
      }
      return factLen;
    },
    fictionText: function () {
      let fictionLen = 0;
      for (let index = 0; index < this.article.ffs.length; index++) {
        if (this.article.ffs[index].type == "FICTION") {
          ++fictionLen;
        }
      }
      return fictionLen;
    },
    articleSubHead: function () {
      // let viewCount = 0;
      // if (this.article.vcs.length === 0) viewCount = 0;
      // else viewCount = this.article.vcs[0].viewCount;
      let viewCount = this.article.view_count;
      let location = this.article.locationList[0];
      let locationStr = "";
      if (location.city) {
        locationStr = location.city;
      } else if (location.state) {
        locationStr = location.state;
      } else if (location.country) {
        locationStr = location.country;
      }
      return (
        // viewCount + " views. " + this.timeAgo(this.article.created_at) + "."
        this.timeAgo(this.article.created_at) + "."
      );
    },
    articleLocation: function () {
      let location = this.article.locationList[0];
      let locationStr = "";
      if (location.city) {
        locationStr = location.city;
      } else if (location.state) {
        locationStr = location.state;
      } else if (location.country) {
        locationStr = location.country;
      }
      return "📌 " + locationStr;
    },
  },
  methods: {
    toTitleCase: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    routeToArticle: function () {
      if (this.article.author.is_publisher) {
        window.open(this.article.text, "_blank") ||
          window.location.replace(this.article.text);
        // this.$router.push({ path: this.article.text });
      } else {
        this.$router.push(this.articleLink);
      }
    },
    timeAgo: function (date) {
      return Stime.timeAgo(date);
    },
    changeToHttps: function (imageUrl) {
      let newUrl = imageUrl.replace("http:", "https:");
      return newUrl;
    },
    search: function () {
      // this.$emit("getArticlesByLocation", payload);
      console.log(
        "Location in store is when search clicked on card ",
        this.$store.state.location
      );
      this.$router.push({
        path: "/",
        query: {
          line3: this.$store.state.location.line3,
          city: this.$store.state.location.city,
          state: this.$store.state.location.region,
          country: this.$store.state.location.country,
          category: this.article.category,
        },
      });
    },
  },
};

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
  timeAgo: function(date) {
    const pastMoment = dayjs(date);
    return pastMoment.fromNow(); // 2 hours ago
  }
};

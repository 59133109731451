const uuidv4 = require("uuid/v4");
import { Config } from "@/config.js";
import axios from "axios";
export default {
  getUploadUrl(contentType, filename) {
    let headers = { ContentType: "application/json" };
    let data = { "content-type": contentType, filename: filename };
    return axios.post(Config.HOST + "/api/upload_session_url/", data);
  },
  getServingUrl(filename) {
    return axios.post(Config.HOST + "/api/servingurl/", {
      filename: filename
    });
  },
  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI
      .split(",")[0]
      .split(":")[1]
      .split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  },
  dataURLtoFile: function(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  },
  uploadImage2(imagedata, index) {
    var mimeTypes = require("mimetypes");
    var image = imagedata,
      mimeType = image.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)[1],
      fileName = uuidv4() + "-original." + mimeTypes.detectExtension(mimeType),
      base64EncodedImageString = image.replace(/^data:image\/\w+;base64,/, "");
    let imageBin = this.dataURItoBlob(imagedata, mimeType);
    var headers = {
      // "Content-Encoding": "base64",
      "Content-Type": mimeType,
      "Access-Control-Allow-Origin": "*"
    };
    var file = this.dataURLtoFile(imagedata, fileName);
    let fd = new FormData();
    fd.append("image", file);
    var self = this;
    return new Promise(function(resolve, reject) {
      self
        .getUploadUrl(mimeType, fileName)
        .then(response => {
          let uploadUrl = response.data.upload_session_url;
          let config = {
            headers: headers
          };
          const instance = axios.create();
          delete instance.defaults.headers.common["Authorization"];
          instance
            .put(uploadUrl, imageBin, config)
            .then(resp => {
              if (resp.status === 200) {
                self.getServingUrl(fileName).then(response => {
                  resolve({ name: response.data.serving_url, index: index });
                });
              } else {
                reject(
                  "Something is wrong in uploading image to google storage"
                );
              }
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

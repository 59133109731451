
const uuidv4 = require("uuid/v4");
import ArticlesService from "@/services/articlesService.js";
import Media from "@/util/media.js";
export default {
  name: "SnapDialog",
  props: ["file"],
  data: () => ({
    caption: "",
    notification: ""
  }),
  created: function() {},
  computed: {
    url() {
      return URL.createObjectURL(this.file);
    }
  },
  methods: {
    onClosePreview() {
      this.file = null;
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    onSnapPublish() {
      var self = this;
      let imageDataUri = null;
      this.getBase64(this.file).then(data => {
        Media.uploadImage2(data, 0)
          .then(function(resp) {
            let payload = {
              article: {
                text: resp.name,
                title: self.caption,
                locationList: [self.$store.state.location],
                type: "SNAP"
              }
            };
            ArticlesService.postBody = payload;
            ArticlesService.headers = {
              Authorization: self.$auth.strategy.token.get()
            };
            ArticlesService.post()
              .then(({ data }) => {
                self.notification = "Publish successful.";
              })
              .catch(err => {
                self.notification = "Publish failed, please try again.";
              });
          })
          .catch(err => {
            this.notification = "Publish failed, please try again.";
          });
      });
      // const reader = new FileReader();

      // reader.addEventListener(
      //   "load",
      //   function() {
      //     // convert image file to base64 string
      //     imageDataUri = reader.result;

      //   },
      //   false
      // );
    }
  }
};

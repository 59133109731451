
import SnapDialog from "@/components/SnapDialog";
export default {
  name: "SFooter",
  data: () => ({
    activeVal: "home",
    showDialog: false,
    file: null
  }),
  watch: {
    $route(to, from) {
      console.log("bottom bar path is ", to);
    }
  },
  beforeMount() {
    let activeString = $nuxt.$route.path.split("/")[1];
    if (activeString.length > 0) {
      this.activeVal = activeString;
    }
  },
  methods: {
    showHome: function() {
      this.$router.push({
        path: "/"
      });
    },
    openSettings() {
      if (this.$auth.loggedIn) {
        this.$router.push({
          path: "/settings",
          params: {}
        });
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    },
    showCreateDialog() {
      this.file = null;
      this.showDialog = true;
    },

    openEditor() {
      if (this.$auth.loggedIn) {
        this.$router.push({
          path: "/editor",
          params: {}
        });
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    },

    showBookmarks: function() {
      this.activeVal = "bookmarks";
      this.$router.push({
        path: "/bookmarks"
      });
    },
    onImageEdit() {
      if (this.$auth.loggedIn) {
        var fileupload = document.getElementById("ImageUpload");
        fileupload.click();
      } else {
        this.$router.push({
          path: "/login"
        });
      }
    },
    onFileChange(e) {
      this.file = e.target.files[0];
      this.showDialog = false;
    }
  }
};

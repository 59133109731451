
import SnapView from "@/components/SnapView";
export default {
  name: "SnapTray",
  props: {
    snaps: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      snap: null,
    };
  },
  methods: {
    changeToHttps: function (imageUrl) {
      let newUrl = imageUrl.replace("http:", "https:");
      return newUrl;
    },
    onSnapClick: function (snap) {
      this.snap = snap;
    },
    closeSnap: function () {
      this.snap = null;
    },
  },
};

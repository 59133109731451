import { render, staticRenderFns } from "./index.vue?vue&type=template&id=78770615&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CategoriesTrayDP: require('/Users/aster/Projects/streetnuxt/src/components/CategoriesTrayDP.vue').default,CategoriesTray: require('/Users/aster/Projects/streetnuxt/src/components/CategoriesTray.vue').default,SnapTray: require('/Users/aster/Projects/streetnuxt/src/components/SnapTray.vue').default,ArticleItemNew: require('/Users/aster/Projects/streetnuxt/src/components/ArticleItemNew.vue').default})

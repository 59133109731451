import { render, staticRenderFns } from "./ArticleItemNew.vue?vue&type=template&id=43207c7e&scoped=true&"
import script from "./ArticleItemNew.vue?vue&type=script&lang=js&"
export * from "./ArticleItemNew.vue?vue&type=script&lang=js&"
import style0 from "./ArticleItemNew.vue?vue&type=style&index=0&id=43207c7e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43207c7e",
  null
  
)

export default component.exports
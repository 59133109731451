
import store from "@/store";
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import UserService from "@/services/userService.js";
import {
  FETCH_CURRENT_LOCATION,
  FETCH_LOGGED_IN_USER_PROFILE,
} from "@/store/actionTypes.js";
export default {
  name: "sheader",
  components: {
    // vuegoogleautocomplete: VueGoogleAutocomplete
  },
  created() {
    console.log(this.$store.state.location);
    console.log("auth user object is ", this.$auth.user);

    this.userInfo.line3 = this.adjustFontSizeByOverflow(
      this.$store.state.location.line3
    );
    this.userInfo.city = this.adjustFontSizeByOverflow(
      this.$store.state.location.city
    );
    this.userInfo.state = this.adjustFontSizeByOverflow(
      this.$store.state.location.state
    );
    this.userInfo.country = this.adjustFontSizeByOverflow(
      this.$store.state.location.country
    );
    if (
      this.$auth.loggedIn &&
      !this.$store.state.profile.loggedInUserProfile.email
    ) {
      let userInfo = {
        userAccessToken: this.$auth.strategy.token.get(),
      };
      this.$store
        .dispatch("profile/FETCH_LOGGED_IN_USER_PROFILE", userInfo)
        .then(
          (resp) => {
            console.log(
              "Done getting logged in user profile in new action",
              resp
            );
            console.log(this.$store.state.profile.loggedInUserProfile);
          },
          (error) => {
            console.error(
              "Erron in calling new action LOGGED IN USER PROFILE",
              error
            );
          }
        );
    } else {
      console.log(
        "Already have logged in user profile object ",
        this.$store.state.profile.loggedInUserProfile
      );
    }
  },
  mounted() {},
  beforeMount() {
    console.log("mounting sheader....");
    console.log("Article location list is ", this.locationList);
    if (this.locationList.length === 0) {
      this.getUserCityState();
    }
  },
  data: () => ({
    selectedLocation: null,
    locations: [],
    locationservice: null,
    userInfo: { line3: "", city: "Loading ...", state: "", country: "" },
    //profileUrl : this.$auth.user.profile_pic
  }),
  props: {
    mode: {
      type: String, //can be home, editor
      default: "home",
    },
    userId: {
      type: Number,
      default: 1,
    },
    onPublish: {
      type: Function,
      default: function () {
        console.log("clicked on publish..");
      },
    },
    onSave: {
      type: Function,
      default: function () {
        console.log("clicked on save..");
      },
    },
    onDelete: {
      type: Function,
      default: function () {
        console.log("clicked on delete..");
      },
    },
    onEdit: {
      type: Function,
      default: function () {
        console.log("clicked on edit..");
      },
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    locationList: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {},
  computed: {
    profileUrl: function () {
      return this.$store.state.profile.loggedInUserProfile.image;
    },
    hasProfilePic: function () {
      if (
        this.$auth.loggedIn &&
        this.$store.state.profile.loggedInUserProfile.image
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLoggedIn: function () {
      return this.$auth.loggedIn;
      // if (this.$auth.user.userAccessToken) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
    getUserName: function () {
      if (this.$auth.loggedIn) {
        return this.$store.state.profile.loggedInUserProfile.firstname;
      }
    },
    getUserFirstLetter: function () {
      if (this.getUserName) {
        return this.getUserName[0];
      } else {
        return "?";
      }
    },
    getAddressString: function () {
      console.log("computing address string: " + JSON.stringify(this.userInfo));
      let addressInfo;
      if (this.locationList.length > 0) {
        console.log("Getting address string from article");
        addressInfo = this.locationList[0];
      } else {
        console.log("Getting address string from current location");
        addressInfo = this.userInfo;
      }
      if (addressInfo.line3 && addressInfo.line3.length > 0) {
        this.adjustFontSizeByOverflow(addressInfo.line3);
        return addressInfo.line3;
      }
      if (addressInfo.city && addressInfo.city.length > 0) {
        this.adjustFontSizeByOverflow(addressInfo.city);
        return addressInfo.city;
      }
      if (addressInfo.state && addressInfo.state.length > 0) {
        this.adjustFontSizeByOverflow(addressInfo.state);
        return addressInfo.state;
      }
      if (addressInfo.country && addressInfo.country.length > 0) {
        this.adjustFontSizeByOverflow(addressInfo.country);
        return addressInfo.country;
      }
    },
    profilePic: function () {
      return this.profileUrl ? this.profileUrl.replace("http:", "https:") : "";
    },
  },
  methods: {
    goBack: function () {
      if (window.history > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push({ path: "/" });
      }
    },
    onLoginClick: function () {
      this.$router.push({ path: "/login" });
    },
    onRegisterClick: function () {
      this.$router.push({ path: "/register" });
    },
    changePassword: function () {
      this.$router.push({ path: "/passwordchange" });
    },
    onSearch: function (payload) {
      // this.$emit("getArticlesByLocation", payload);

      this.$router.push({
        path: "/",
        query: {
          line3: payload.line3,
          city: payload.city,
          state: payload.state,
          country: payload.country,
        },
      });
    },
    getAddressData: function (addressData, placeResultData, id) {
      console.log(addressData);
      console.log(placeResultData);
      console.log(id);
      var addressQuery = {};
      for (var i = 0; i < placeResultData.address_components.length; i++) {
        let currPlaceDesc = placeResultData.address_components[i].types;
        if (
          placeResultData.address_components[i].types.indexOf("locality") != -1
        ) {
          addressQuery.line3 = placeResultData.address_components[i].long_name;
        } else if (
          placeResultData.address_components[i].types.indexOf(
            "administrative_area_level_2"
          ) != -1
        ) {
          addressQuery.city = placeResultData.address_components[i].long_name;
        } else if (
          placeResultData.address_components[i].types.indexOf(
            "administrative_area_level_1"
          ) != -1
        ) {
          addressQuery.state = placeResultData.address_components[i].long_name;
        } else if (
          placeResultData.address_components[i].types.indexOf("country") != -1
        ) {
          addressQuery.country =
            placeResultData.address_components[i].long_name;
        } else {
          console.log(
            "None of the above are found ",
            placeResultData.address_components[i]
          );
          continue;
        }
      }
      console.log("addressQuery is ");
      console.log(addressQuery);
      this.isSearch = false;
      this.userInfo.line3 = addressQuery.line3;
      this.userInfo.city = addressQuery.city;
      this.userInfo.state = addressQuery.state;
      this.userInfo.country = addressQuery.country;
      this.onSearch(addressQuery);
    },
    searchClicked: function () {
      this.isSearch = true;
      var self = this;
      this.$nextTick(() => {
        self.$refs.locationsearch.focus();
      });
    },
    searchBlur: function () {
      this.$refs.locationsearch.clear();
    },
    getUserProfile() {
      this.$router.push({
        path: "/profile/" + this.$store.state.profile.loggedInUserProfile.email,
      });
    },
    getUserCityState: function () {
      var self = this;
      this.$store.dispatch("location/FETCH_CURRENT_LOCATION").then(
        (response) => {
          self.userInfo.line3 = self.$store.state.location.line3;
          self.userInfo.city = self.$store.state.location.city;
          self.userInfo.state = self.$store.state.location.region;
          self.userInfo.country = self.$store.state.location.country;
          console.log(
            "Fetched user location: " + JSON.stringify(self.userInfo)
          );
          // self.$emit("getArticlesByLocation", self.userInfo);
        },
        (error) => {
          console.error(
            "Got nothing from server. Prompt user to check internet connection and try again"
          );
        }
      );
    },
    showBookmarks: function () {
      this.$router.push({
        path: "/Bookmarks",
      });
    },
    showPosts: function () {
      this.$router.push({
        path: "/Posts",
      });
    },
    showDrafts: function () {
      this.$router.push({
        path: "/Drafts",
      });
    },
    userLogout: function () {
      this.$auth.logout();
    },
    adjustFontSizeByOverflow: function (newTitle) {
      if (this.$refs.locationtitle) {
        let fontSize = "60px";
        if (window.innerWidth < 500) {
          if (newTitle.length <= 15) {
            fontSize = "30px";
          } else if (newTitle.length < 25) {
            fontSize = "20px";
          } else if (newTitle.length < 35) {
            fontSize = "15px";
          } else {
            fontSize = "10px";
          }
        }
        this.$refs.locationtitle.style.fontSize = fontSize;
        console.log(this.$refs.locationtitle.style.fontSize);
      }
    },
  },
};
